import '../../global.css'

import * as React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import BasePage from '../../components/basePage';
import { FlexColumn, FlexContainer } from '../../components/flex';
import ProjectThumbnail from '../../components/projectThumbnail';
import seasonForDate from '../../season'

const Season = styled(FlexContainer)`
    flex-wrap: wrap;
`;

const groupBySeason = queryProjects => {
    const projects = {};

    for (let project of queryProjects) {
        const date = new Date(`${project.frontmatter.date}T00:00:00Z`);
        let currentSeason = seasonForDate(date);

        const year = date.getFullYear();
        let group;

        if (currentSeason == 'Winter') {
            group = `${currentSeason} ${year - 1}/${year}`;
        } else {
            group = `${currentSeason} ${year}`;
        }

        if (!projects[group]) projects[group] = [];

        projects[group].push(project);
    }

    return projects;
};

export default function Template({
    data,
}) {
    const projects = groupBySeason(data.allMarkdownRemark.nodes);

    return (
        <BasePage pageTitle='Projects'>
            <FlexColumn>
                <h1>Projects</h1>

                {Object.keys(projects).map(season => <>
                    <h3>
                        <i>{season}</i>
                    </h3>

                    <Season marginBottom={5}>
                        {projects[season].map(project => <ProjectThumbnail
                            name={project.frontmatter.name}
                            style={project.frontmatter.style}
                            image={project.frontmatter.images[0]}
                            slug={project.parent.relativeDirectory}
                            />
                        )}
                    </Season>
                </>)}
            </FlexColumn>
        </BasePage>
    )
};

export const pageQuery = graphql`
    query {
        allMarkdownRemark {
            nodes {
                parent {
                    ... on File {
                        relativeDirectory
                    }
                }
                frontmatter {
                    date,
                    name,
                    style,
                    images {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;