import * as React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { useSpring, animated } from 'react-spring'

import { FlexColumn } from './flex'

const Thumbnail = styled(GatsbyImage)`
    height: 150px;
   

    @media screen and (min-width: 451px) {
        width: 150px;
    }

    @media screen and (max-width: 450px) {
        width: 100%;
    }

    img {
        // Safari doesn't properly respect border-radius and needs it on the img
        border-radius: 5px;
    }

    border-radius: 5px;
`;

const Style = styled.i`
    font-size: 9px;
    color: #5B5B5B;
`;

const Name = styled.span`
    font-size: 12px;
    margin-top: 10px;
`;

const LinkWrapper = styled(Link)`
    text-decoration: none;
    color: white;
`;

const ThumbnailWrapper = styled(FlexColumn)`
    @media screen and (min-width: 451px) {
        width: 150px;
    }

    @media screen and (max-width: 450px) {
        width: 100%;
    }
`;

const ProjectThumbnail = ({ name, style, image, slug }) => {
    const [mouseDown, setMouseDown] = React.useState(false);

    const scale = useSpring({
        transform: mouseDown ? 'scale(0.9)' : 'scale(1)'
     });

    return <animated.div style={scale}>
        <LinkWrapper to={`/projects/${slug}`} onMouseDown={() => setMouseDown(true)} onMouseOut={() => setMouseDown(false)}>
            <ThumbnailWrapper marginTop={4} marginRight={7}>
                <Thumbnail alt={`${name} thumbnail`} image={getImage(image)} />
                <Name>{name}</Name>
                <Style>{style}</Style>
            </ThumbnailWrapper>
        </LinkWrapper>
    </animated.div>;
};

export default ProjectThumbnail;